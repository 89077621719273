import React from "react"
import SEO from "../components/seo"
import "../styles/404.css"
import Hero from "../components/hero";
import {BurgerMenuIconAnimation} from "../constants/layoutStyle";
import BurgerMenu from "../components/burgerMenu";
import TopPageLayout from "../components/topPageLayout";

export default function NotFound() {
  return (
    <>
      <SEO title="404 Not Found" description="ページが見つかりません" />
      <TopPageLayout>
        <BurgerMenu iconAnination={BurgerMenuIconAnimation.Visible} />
        <div className="top-main not-found-message">
          <Hero />
          <main className="main-area">
            <h1>404 Not Found</h1>
          </main>
        </div>
      </TopPageLayout>
    </>
  )
}
